import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BookingStateChip from './BookingStateChip';
import BookingView from './BookingView';
import { useState } from 'react';
import { RSPost } from '../../Request';
import { TableContainer } from '@mui/material';

export default function BookingTable(props) {

  const { bookings, simple } = props

  const [ open, setOpen ] = useState(false)
  const [ booking, setBooking ] = useState(null)

  const viewBooking = (booking) => {
    setBooking(null)
    setOpen(true)
    RSPost('BookingView', {booking: booking})
      .then(data => {
        setBooking(data)
      })
      .catch(err => {
      })
  }

  return (
    <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>提交时间</TableCell>
          <TableCell>客户</TableCell>
          <TableCell>城市</TableCell>
          <TableCell>公寓</TableCell>
          <TableCell>房型</TableCell>
          <TableCell>租期</TableCell>
          <TableCell>顾问</TableCell>
          {!simple && <TableCell>状态</TableCell>}
        </TableRow>
      </TableHead>

      <TableBody>
        { bookings && bookings.map((booking, index) => 
        <TableRow key={index}>
          <TableCell>{booking.create_date}</TableCell>
          <TableCell onClick={()=>viewBooking(booking)} sx={{cursor: 'pointer', color: '#0288d1'}}>{booking.name}</TableCell>
          <TableCell>{booking.city}</TableCell>
          <TableCell>{booking.accommodation}</TableCell>
          <TableCell>{booking.room_type}</TableCell>
          <TableCell>{booking.check_in}~{booking.check_out}</TableCell>
          <TableCell>{booking.agent_name?booking.agent_name:'-'}</TableCell>
          {!simple && <TableCell><BookingStateChip id={booking.create_date} state={booking.state} /></TableCell>}
        </TableRow>
        )}
      </TableBody>
      <BookingView open={open} setOpen={setOpen} booking={booking} />
    </Table>
    </TableContainer>
  )
}