import { Card, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import { RSGet, RSLog } from '../../Request'
import { currencySymbol } from '../../utils/country'
import { StyledTableCell, StyledTableRow } from '../../layout/StyledTable'

/* 佣金结算记录表格 */

export default function Statement()
{

  const [statement, setStatement] = useState([])

  useEffect(() => {
    RSGet('Commission')
      .then(data => {
        RSLog(data)
        setStatement(data)
      })
      .catch(err => {

      })
  }, [])

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>返佣日期</StyledTableCell>
            <StyledTableCell>支付金额</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          { statement && statement.map(s => 
          <StyledTableRow key={s.id}>
            <StyledTableCell>{s.date}</StyledTableCell>
            <StyledTableCell>{currencySymbol(s.currency)}{s.amount}</StyledTableCell>
          </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  )
}