import axios from 'axios'
import Qs from 'qs'
import { startLoading, stopLoading } from './utils/loading'

const endpoint = "https://api2.51room.com/AgentPortal/"
axios.defaults.withCredentials = true


export function Get(api, callback) {
  const url = endpoint+api

  axios.get(url)
    .then(res => {
      if(res.data.data) {
        callback(res.data.data)
      } else {
        callback(false)
      }
    })
    .catch(function (error) {
      callback(false)
    })
}

export const Post = (api, data) => { return new Promise((resolve, reject) => {
  const url = endpoint+api
  const headers = {'Content-Type': 'application/json'}
  axios.post(url, Qs.stringify(data), headers)
    .then(res => {
      resolve(res.data)
    })
    .catch(error => {
      // RSLog('出错')
      if(error.response) {
        // RSLog(error.response.data)

        if(error.response.data.error === 401) {
          RSLog('登录超时')
          removeToken()
          window.location.href="/"
        }
        RSLog(error.response.data.messages.error)
        reject(error.response.data.messages.error)
      } else if (error.request) {
        RSLog(error.request);
      } else {
        RSLog(error.message);
      }
    })
})}


// Request Get
export const RSGet = (api) =>{ return new Promise((resolve, reject) => {
  const postData = { token: getToken() }

  Post(api, postData)
    .then(data => {
      //RSLog(data)
      if(data.token) {
        RSLog('刷新令牌')
        setToken(data.token)
      }
      resolve(data.data)
    })
    .catch(err => {
      reject(err)
    })
})}

// Request Post
export const RSPost = (api, data) =>{ return new Promise((resolve, reject) => {
  const postData = {
    token: getToken(),
    ...data
  }
  startLoading()
  Post(api, postData)
    .then(data => {
      //RSLog(data)
      if(data.token) {
        setToken(data.token)
      }
      stopLoading()
      resolve(data.data)
    })
    .catch(err => {  
      stopLoading()
      reject(err)
    })
})}


// 覆盖console.log 生产模式下不log
export function RSLog(msg) {
  if (window.location.hostname === 'agent.51room.com') return;
  console.log(msg);
}


// Access Token Set and Get
export function getToken() {
  return window.localStorage.getItem('rsToken')
}

export function setToken($token) {
  window.localStorage.setItem('rsToken', $token)
}

export function removeToken() {
  window.localStorage.removeItem('rsToken')
}