import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BookingStateChip from './BookingStateChip';
import Pagination from '@mui/material/Pagination'
import { Box } from '@mui/material'
import BookingView from './BookingView';
import { useState } from 'react';
import { RSLog, RSPost } from '../../Request';

function BookingList(props) {

  const { bookings, page, setPage, pageCount, reload } = props

  const [ open, setOpen ] = useState(false)
  const [ booking, setBooking ] = useState(null)

  const handleChangePage = (e, v) => {
    setPage(v)
  }

  const viewBooking = (booking) => {
    RSPost('BookingView', {booking: booking})
      .then(data => {
        RSLog(data)
        setBooking(data)
        setOpen(true)
      })
      .catch(err => {
      })
  }

  return (
    <Grid container mt={2}>
      <Grid xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>提交时间</TableCell>
                <TableCell>客户</TableCell>
                <TableCell>城市</TableCell>
                <TableCell>公寓</TableCell>
                <TableCell>房型</TableCell>
                <TableCell>租期</TableCell>
                <TableCell>顾问</TableCell>
                <TableCell>状态</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { bookings && bookings.map((booking, index) => 
              <TableRow key={index}>
                <TableCell>{booking.create_date}</TableCell>
                <TableCell onClick={()=>viewBooking(booking)} sx={{cursor: 'pointer', color: '#0288d1'}}>{booking.name}</TableCell>
                <TableCell>{booking.city}</TableCell>
                <TableCell>{booking.accommodation}</TableCell>
                <TableCell>{booking.room_type}</TableCell>
                <TableCell>{booking.check_in}~{booking.check_out}</TableCell>
                <TableCell>{booking.agent_name}</TableCell>
                <TableCell><BookingStateChip id={booking.create_date} state={booking.state} /></TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        { bookings && pageCount>1 && <Pagination count={pageCount} shape="rounded" page={page} onChange={handleChangePage} /> }
        </Box>
      </Grid>

      <BookingView open={open} setOpen={setOpen} booking={booking} reload={reload} />
    </Grid>
  )
}

export default BookingList