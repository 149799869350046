import { Dialog, Stack, Button, Alert, TextField, DialogContent } from "@mui/material"
import { useState } from "react"
import { passwordStrength } from 'check-password-strength'
import { RSLog, RSPost } from "../../Request"

import { toast } from 'react-toastify';

function PasswordUpdateDialog(props) {

  const { open, setOpen, agent } = props

  const [warning, setWarning] = useState(null)
  const [password, setPassword] = useState('')

  const closeDialog = () => {
    setOpen(false)
  }

  const onChangePassword = (e) => {
    setWarning(null)
    setPassword(e.target.value)
  }

  const updatePassword = () => {
    const strength = passwordStrength(password)
    console.log(strength)
    if(strength.length < 8) {
      setWarning('密码小于8位')
      return false
    }
    if(!strength.contains.includes('lowercase')) {
      setWarning('密码缺少小写字母')
      return false
    }
    if(!strength.contains.includes('uppercase')) {
      setWarning('密码缺少大写字母')
      return false
    }
    if(!strength.contains.includes('number')) {
      setWarning('密码缺少数字')
      return false
    }
    if(!strength.contains.includes('symbol')) {
      setWarning('密码缺少特殊字符')
      return false
    }

    if(agent) {
      RSPost('Team/UpdatePassword', {password: password, username: agent.username})
        .then(data => {
          RSLog(data)
          setPassword('')
          closeDialog()
          toast.success("密码已更新");
        })
        .catch(err => {
        })
    } else {
      RSPost('UpdatePassword', {password: password})
        .then(data => {
          RSLog(data)
          setPassword('')
          closeDialog()
          toast.success("密码已更新");
        })
        .catch(err => {
        })
    }
  }

  return (
    <Dialog onClose={closeDialog} open={open}>
      <DialogContent>
      <Stack spacing={2}>
        <TextField variant="outlined" label="新密码" type="password" value={password} onChange={onChangePassword} />
        <small>请包含字母大小写,数字和特殊字符</small>
        {warning && <Alert severity="error">{warning}</Alert>}
        <Button variant="contained" onClick={updatePassword}>更新密码</Button>
      </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default PasswordUpdateDialog

