import { useEffect, useState } from 'react'
import { RSLog, RSGet } from '../../Request'
import { Button, Box, Dialog, DialogTitle, DialogContent, IconButton, Divider } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import CloseIcon from '@mui/icons-material/Close';

import UKFlag from '../../images/gb.svg'
import AUFlag from '../../images/au.svg'

function CitySelector(props) {

  const { setCityID } = props
  const [country, setCountry] = useState('英国')
  const [city, setCity] = useState('伦敦')
  const [UKCities, setUKCities] = useState([])
  const [AUCities, setAUCities] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(()=>{
    loadCity()
  }, [])


  const loadCity = () => {
    RSGet('student-accommodation/city')
      .then(data => {
        RSLog(data)
        setUKCities(data.uk)
        setAUCities(data.au)
      })
      .catch(err => {
     
      }) 
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const selectCity = (country, city) => {
    setCountry(country)
    setCity(city.name_cn)
    setCityID(city.id)
    closeDialog()
  }

  return (
    <>
    <Button variant='outlined' startIcon={<PublicIcon />} onClick={openDialog}>{country} - {city}</Button>
    <Dialog
      maxWidth="md"
      open={open}
      onClose={closeDialog}
    >
      <DialogTitle>
        选择城市
        <IconButton onClick={closeDialog} sx={{float: 'right'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box ml={1} mb={1}><img src={UKFlag} width="17" alt="gb" /> 英国</Box>
        <Box>
        {UKCities && UKCities.map((item)=> 
          <Button key={item.id} onClick={()=>selectCity('英国', item)} sx={{color: '#666'}}>{item.name_en} {item.name_cn}</Button>
        )}
        </Box>

        <Box mt={2} mb={2}><Divider /></Box>
        
        <Box ml={1} mb={1}><img src={AUFlag} width="17" alt="au" /> 澳洲</Box>
        {AUCities && AUCities.map((item)=> 
          <Button key={item.id} onClick={()=>selectCity('澳洲', item)} sx={{color: '#666'}}>{item.name_en} {item.name_cn}</Button>
        )}
      </DialogContent>
    </Dialog>
    </>
    
  )
}

export default CitySelector