import { AppBar, Box, Button, Container, Toolbar, Typography, Stack, IconButton,
  Menu, MenuItem, Divider
} from '@mui/material'
import { useEffect, useState } from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { setSigned, AppState } from '../redux/AppSlice'
import { removeToken } from '../Request'
import PasswordUpdateDialog from '../pages/team/PasswordUpdateDialog';

function Top() {

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null)
  const [accountMenuOpen, setAccountMenuOpen] = useState(false)
  const [section, setSection] = useState('')
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const { scope } = useSelector(AppState)

  useEffect(()=>{
    var segments = window.location.pathname.split('/')
    if(segments.length > 1) setSection(segments[1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const openAccountMenu = (e) => {
    setAccountMenuAnchorEl(e.currentTarget)
    setAccountMenuOpen(true)
  }

  const closeAccountMenu = () => {
    setAccountMenuAnchorEl(null)
    setAccountMenuOpen(false)
  }

  const openPasswordDialog = () => {
    setPasswordDialogOpen(true)
    closeAccountMenu()
  }

  // 登出系统
  const signout = () => {
    removeToken()
    dispatch(setSigned(false))
    navigate("");
  }

  const TopMenuItem = (props) => {
    const { path, text } = props
    
    return (
      <Link to={"/"+path}>
        <Button 
          sx={{color: 'white'}}
          color="warning"
          variant={path===section?"contained":"text"} 
        >
          {text}
        </Button>
      </Link>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Container maxWidth="xl">
          <Toolbar variant='dense' disableGutters>
            <Typography sx={{ mr: 4, fontWeight: 'bold' }}>{scope['company']}</Typography>
            <Stack sx={{ flexGrow: 1 }} direction="row" spacing={2}>
              <TopMenuItem text="首页" path="" />
              <TopMenuItem text="学生公寓" path="accommodation" />
              <TopMenuItem text="订单管理" path="booking" />
              {scope.admin && <TopMenuItem text="团队管理" path="team" />}
              {scope.admin && <TopMenuItem text="佣金结算" path="commission" />}
            </Stack>


            <Stack direction="row" spacing={2}>

              {/*账号按钮*/}
              <IconButton
                sx={{color: 'white'}}
                onClick={openAccountMenu}
              >
                <AccountCircleOutlinedIcon />
              </IconButton>

            </Stack>
            
            <Menu
              open={accountMenuOpen}
              anchorEl={accountMenuAnchorEl}
              onClose={closeAccountMenu}
            >
              <MenuItem>{scope['user']}</MenuItem>
              <Divider />
              <MenuItem onClick={openPasswordDialog}>修改密码</MenuItem>
              <MenuItem onClick={signout}>登出系统</MenuItem>
            </Menu>
            <PasswordUpdateDialog open={passwordDialogOpen} setOpen={setPasswordDialogOpen} />
          </Toolbar>

        </Container>
      </AppBar>
      
    </Box>
  )
}

export default Top