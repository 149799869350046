import { Dialog, Button, DialogContent, DialogTitle, TextField, Chip, Stack, ListItemButton } from "@mui/material";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { RSPost, RSLog } from "../../Request";
import { toast } from 'react-toastify';
import AccommodationView from './AccommodationView'

export default function AccommodationSearch() {

  const [open, setOpen] = useState(false)
  const [keywords, setKeywords] = useState('')
  const [accommodations, setAccommodations] = useState([])
  const [viewOpen, setViewOpen] = useState(false)
  const [accommodation, setAccommodation] = useState(null)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const changeKeywords = (e) => {
    setKeywords(e.target.value)
  }

  const keyEnterDown = (e) => {
    if(e.key === 'Enter' && keywords !== '') {
      setAccommodations([])
      if(keywords.length <= 2) toast.warning('搜索关键字不得小于2个字符')
      else search()
    }
  }

  const search = () => {
    RSPost('student-accommodation/search', {keywords: keywords})
    .then(data => {
      RSLog(data)
      setAccommodations(data)
    })
    .catch(err => {
    })
  }

  const loadAccommodation = (id) => {
    RSPost('student-accommodation/view', {id: id})
      .then(data => {
        RSLog(data)
        setAccommodation(data)
      })
      .catch(err => {
      })
  }

  const viewAccommodation = (id) => {
    loadAccommodation(id)
    setViewOpen(true)
  }

  return(
    <>
    <Button 
      variant='outlined' 
      startIcon={<SearchIcon />} 
      onClick={openDialog}
    >
      查找
    </Button>

    <Dialog 
      fullWidth
      open={open} 
      onClose={closeDialog}
    >
      <DialogTitle>
        <TextField fullWidth label="查找房源" value={keywords} onChange={changeKeywords} onKeyDown={keyEnterDown} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
        {accommodations.map(accommodation => 
        <ListItemButton key={accommodation.id} onClick={()=>viewAccommodation(accommodation.id)}>
     
            <Chip label={accommodation.city} color="primary" sx={{mr: '0.5rem'}} /> {accommodation.name} 
        
        </ListItemButton>
        )}
        </Stack>
      </DialogContent>
    </Dialog>
    <AccommodationView open={viewOpen} setOpen={setViewOpen} accommodation={accommodation} setAccommodation={setAccommodation} />
    </>
  )
}