import { BrowserRouter as Router } from "react-router-dom"
import { useEffect } from "react";
import { getToken } from './Request'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, setSigned } from './redux/AppSlice'
import SignCard from "./layout/SignCard";
import Layout from "./layout/Layout";

function App() {
  
  const { signed } = useSelector(AppState) // 登录状态
  const dispatch = useDispatch()

  useEffect(()=>{
    const token = getToken()
    if(token) {
      dispatch(setSigned(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      {!signed && <SignCard />}
      {signed && <Layout />}
    </Router>
  );
  
}

export default App;
