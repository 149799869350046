import { Dialog, Stack, Button, TextField, DialogContent } from "@mui/material"
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from "react"
import { RSLog, RSPost } from "../../Request"

import { toast } from 'react-toastify';

function MemberUpdateDialog(props) {

  const { open, setOpen, agent, reload } = props

  const [name, setName] = useState('')
  const [booking, setBooking] = useState(false)

  useEffect(() => {
    if(open) {
      setName(agent.name)
      setBooking(agent.booking === '1')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const closeDialog = () => {
    setOpen(false)
  }

  const onChangeName = (e) => {
    setName(e.target.value)
  }

  const onChangeBooking = (e) => {
    setBooking(!booking)
  }

  const updateMember = () => {
    RSPost('Team/UpdateMember', {username: agent.username, name: name, booking: booking?1:0})
      .then(data => {
        RSLog(data)
        closeDialog()
        reload()
        toast.success("已更新设置");
      })
      .catch(err => {
      })
  }

  return (
    <Dialog onClose={closeDialog} open={open}>
      <DialogContent>
      <Stack spacing={2}>
        <TextField variant="outlined" label="顾问名字" value={name} onChange={onChangeName} />
        <FormControlLabel control={<Switch checked={booking} />} label="查看全部订单" onChange={onChangeBooking} />
        <Button variant="contained" onClick={updateMember}>保存</Button>
      </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default MemberUpdateDialog

