import { Chip } from "@mui/material";
import { useEffect, useState } from "react";

function BookingStateChip(props) {

  const { state, id } = props
  const [ label, setLabel ] = useState('')
  const [ color, setColor ] = useState('primary')


  useEffect(()=>{
    switch(state) {
      case '0':
        setLabel('办理中')
        setColor('warning')
      break;

      case '1':
        setLabel('已完成')
        setColor('info')
      break;

      case '2':
        setLabel('已取消')
        setColor('default')
      break;

      default:
      break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Chip label={label} color={color} size="small" sx={{fontSize: 12, lineHeight:1}} />
  )
}

export default BookingStateChip