import { Dialog, DialogContent, Paper, Typography, Box, Divider, Button, Chip } from "@mui/material"
import { Stack } from "@mui/material"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import BookingForm from "../booking/BookingForm";
import { useState } from "react";

function AccommodationView(props) {

  const { open, setOpen, accommodation, setAccommodation } = props
  const [formOpen, setFormOpen] = useState(false)
  const [formData, setFormData] = useState({})

  const closeDialog = () => {
    setAccommodation(null)
    setOpen(false)
  }

  const openForm = (accommodation, roomtype, price) => {
    setFormData({
      accommodation: accommodation.name,
      accommodation_id: accommodation.id,
      city: accommodation.city,
      city_id: accommodation.city_id,
      room_type: roomtype.name,
      price: price.price,
      price_id: price.id,
      weeks: price.weeks,
      check_in: price.start_date,
    })
    setFormOpen(true)
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={closeDialog}
    >
    { accommodation && 
      <DialogContent sx={{backgroundColor: '#eee'}}>
        <Stack spacing={2}>
        <Typography variant="h5">{accommodation.name}</Typography>
        <Typography variant="body2">{accommodation.address}, {accommodation.postcode}</Typography>
        
        <Carousel showThumbs={false} showIndicators={false}>
          {accommodation.imgs.map(img => <img key={img.id} src={img.url+'?x-oss-process=style/desktop_property_list'} width="535" height="349" alt="" />)}     
        </Carousel>
        
        <Paper>
          <Box p={2}>
          <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>{accommodation.intro}</Typography>
          </Box>
        </Paper>

        <Box>
        {accommodation.amenities.map(amenity => <Chip key={amenity} label={amenity} sx={{marginRight: '0.5rem', marginBottom: '0.5rem'}} />)}
        </Box>

        {accommodation.roomtypes.map(roomtype => 
          <Paper key={roomtype.id}>
            <Box p={2}>
              <Carousel showThumbs={false} showIndicators={false}>
                {roomtype.imgs.map(img => <img key={img.id} src={img.url+'?x-oss-process=style/desktop_property_list'} width="535" height="349" alt="" />)}     
              </Carousel>
              <Box my={2}></Box>
              <Typography variant="h6">{roomtype.name}</Typography>
              <Typography variant="body2">床型：{roomtype.bed}</Typography>
              <Typography variant="body2">房型：{roomtype.type}</Typography>
              <Typography variant="body2">{roomtype.description}</Typography>
              {roomtype.prices.map(price => 
                <Box key={price.id}>
                  <Box my={2}><Divider /></Box>
                  { price.state === '0' && <Button variant="contained" sx={{float: 'right'}} onClick={() => openForm(accommodation, roomtype, price)}>预订</Button>}
                  { price.state === '1' && <Button sx={{float: 'right', color: '#666'}} onClick={() => openForm(accommodation, roomtype, price)}>满租</Button>}
                  <Typography variant="h6">£{price.price}/周</Typography>
                  <Typography variant="body2">租期：{price.start_date !== '0000-00-00' && price.start_date} / {price.weeks}周</Typography>
                  {price.note !== '' && <Typography variant="body2">备注：{price.note}</Typography>}
                </Box>
              )}
            </Box>
          </Paper>
        )}
        </Stack>
        <BookingForm open={formOpen} setOpen={setFormOpen} data={formData} />
      </DialogContent>
    }
    </Dialog>
  )
}

export default AccommodationView