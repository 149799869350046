import { Stack } from '@mui/material';
import { useEffect, useState } from 'react'
import Breadcrumb from '../../layout/Breadcrumb';
import { RSLog, RSPost } from '../../Request'
import BookingList from './BookingList';
import BookingSearch from './BookingSearch';

function Booking() {

  const [bookings, setBookings] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const loadData = () => {
    RSPost('Bookings', {page: page})
    .then(data => {
      RSLog(data)
      setBookings(data.bookings)
      setPageCount(data.pageCount)
    })
    .catch(err => {
    })
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Breadcrumb text="订单管理" />
        <BookingSearch />
      </Stack>
      
      <BookingList 
        bookings={bookings}  
        page={page} 
        setPage={setPage} 
        pageCount={pageCount}
        reload={loadData}
      />
    </Stack>
  )
}

export default Booking