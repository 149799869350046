import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DegreePicker(props) {
  const { name, selected, setSelected } = props;

  const handleChange = (event) => {
    setSelected(name, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>学位</InputLabel>
      <Select
        value={selected}
        onChange={handleChange}
      >
        <MenuItem value={0}>本科/预科</MenuItem>
        <MenuItem value={1}>硕士/预科</MenuItem>
        <MenuItem value={2}>其它</MenuItem>
      </Select>
    </FormControl>
  );
}