import { useEffect, useState } from 'react'
import { RSLog, RSGet } from '../../Request'
import { startLoading, stopLoading } from '../../utils/loading'

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack } from '@mui/material';
import PasswordUpdateDialog from './PasswordUpdateDialog';
import MemberUpdateDialog from './MemberUpdateDialog';


function Team() {

  const [team, setTeam] = useState([])
  const [memberDialogOpen, setMemberDialogOpen] = useState(false)
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [currentAgent, setCurrentAgent] = useState(null)

  useEffect(()=>{
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadData = () => {
    startLoading()
    RSGet('Team')
      .then(data => {
        RSLog(data)
        setTeam(data)
        stopLoading()
      })
      .catch(err => {
        stopLoading()
      })
  }

  const openMemberDialog = (agent) => {
    setMemberDialogOpen(true)
    setCurrentAgent(agent)
  }

  const openPasswordDialog = (agent) => {
    setPasswordDialogOpen(true)
    setCurrentAgent(agent)
  }

  return (
    <Grid container>
      <Grid xsOffset={3} xs={6}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>用户名</TableCell>
                <TableCell>顾问</TableCell>
                <TableCell>权限</TableCell>
                <TableCell>订单</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { team && team.map((member, index) => 
              <TableRow key={index}>
                <TableCell>{member.username}</TableCell>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.note}</TableCell>
                <TableCell>{member.booking === '1'?'全部':'仅自己'}</TableCell>
                <TableCell>
                  {
                  member.note !== '技术支持' && 
                  <Stack direction='row' spacing={2}>
                    <Button onClick={()=>openMemberDialog(member)}>用户设置</Button>
                    <Button onClick={()=>openPasswordDialog(member)}>修改密码</Button>
                  </Stack>
                  }
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <MemberUpdateDialog open={memberDialogOpen} setOpen={setMemberDialogOpen} agent={currentAgent} reload={loadData} />
      <PasswordUpdateDialog open={passwordDialogOpen} setOpen={setPasswordDialogOpen} agent={currentAgent} />
    </Grid>
  )
}


export default Team