import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'app',
  initialState: {
    signed: false,
    scope: [],
    loading: false
  },
  reducers: {
    setSigned:(state, action) => {
      state.signed = action.payload
    },
    setScope:(state, action) => {
      state.scope = action.payload
    },
    setLoading:(state, action) => {
      state.loading = action.payload
    }
  },
})

export const { 
  setSigned,
  setScope,
  setLoading
} = slice.actions
export const AppState = state => state.App
export default slice.reducer