import { Dialog, DialogContent, Divider, IconButton, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useEffect, useState } from "react";
import DatePicker from "../form/DatePicker";
import GenderPicker from "../form/GenderPicker";
import Input from "../form/Input";
import CloseIcon from '@mui/icons-material/Close';
import { RSPost } from "../../Request";
import DegreePicker from "../form/DegreePicker";
import { toast } from "react-toastify";

function BookingForm(props) {

  const { open, setOpen, data } = props

  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    customer_dob: '2000-01-01',
    customer_gender: 0,
    customer_degree: 1
  })


  useEffect(()=>{
    console.log(data)




    setFormData({
      ...data,
      ...formData,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  const checkoutData = (check_in, weeks) => {
    if(!check_in || check_in === '0000-00-00') return check_in;
    var check_in_date = new Date(check_in);
    check_in_date.setDate(check_in_date.getDate() + (weeks * 7));
    return check_in_date.toISOString().substring(0, 10);
  }

  const closeDialog = () => {
    setOpen(false)
    setLoading(false)
  }


  const changeData = (name, date) => {
    console.log(name, date)
    setFormData({
      ...formData,
      [`${name}`]: date
    })
  }

  const submit = () => {
    setLoading(true)
    RSPost('BookingSubmit', formData)
      .then(data => {
        console.log(data)
        toast.success('订单提交成功')
        closeDialog()
      })
      .catch(err => {
        closeDialog()
      })
  }

  return (
    <>
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={closeDialog}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <IconButton sx={{float: 'right'}} onClick={closeDialog}><CloseIcon /></IconButton>
            <Typography variant="h5">{data.accommodation} - {data.room_type}</Typography>
            <Typography>£{data.price}/周</Typography>
            <Typography>{data.check_in} 开始 {data.weeks} 周</Typography>
          </Grid>

          <Grid xs={6}><DatePicker label="开始时间" name="check_in" date={(formData.check_in==='0000-00-00')?'2023-09-01':formData.check_in} setDate={changeData}/></Grid>
          <Grid xs={6}><DatePicker label="结束时间" name="check_out" date={formData.check_out} setDate={changeData}/></Grid>
          
          <Grid xs={12}>申请人</Grid>
          <Grid xs={6}><Input name="customer_name" label="姓名(中文)" setText={changeData} /></Grid>
          <Grid xs={6}><Input name="customer_name_en" label="姓名(拼音)" setText={changeData} /></Grid>
          <Grid xs={6}><Input name="customer_university" label="学校(留学就读)" setText={changeData} /></Grid>
          <Grid xs={6}><DegreePicker  name="customer_degree" selected={formData.customer_degree} setSelected={changeData} /></Grid>
          <Grid xs={6}>
            <DatePicker label="生日" name="customer_dob" date={formData.customer_dob} setDate={changeData}/>
          </Grid>
          <Grid xs={6}>
            <GenderPicker name="customer_gender" selected={formData.customer_gender} setSelected={changeData} />
          </Grid>
          <Grid xs={12}><Input name="note" label="要求备注" setText={changeData} /></Grid>

          <Grid xs={12}><Divider /></Grid>
         
          <Grid xs={12}>联系方式</Grid>
          <Grid xs={12}><Input name="customer_email" label="Email" setText={changeData} /></Grid>
          <Grid xs={6}><Input name="customer_mobile" label="手机号" setText={changeData} /></Grid>
          <Grid xs={6}><Input name="customer_wechat" label="微信号" setText={changeData} /></Grid>
          <Grid xs={12}><Input name="customer_address" label="家庭地址" setText={changeData} /></Grid>
          <Grid xs={4}><Input name="customer_postcode" label="邮编" setText={changeData} /></Grid>

          <Grid xs={12}><Divider /></Grid>

          <Grid xs={12}>担保人</Grid>
          <Grid xs={4}><Input name="reference_name" label="姓名" setText={changeData} /></Grid>
          <Grid xs={2}><Input name="reference_relationship" label="关系" setText={changeData} /></Grid>
          <Grid xs={6}><Input name="reference_mobile" label="电话" setText={changeData} /></Grid>
          <Grid xs={12}><Input name="reference_email" label="Email" setText={changeData} /></Grid>


          <Grid xs={12}>
            <LoadingButton loading={loading} variant="contained" fullWidth onClick={submit}>提交订单</LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    </>
  )
}

export default BookingForm