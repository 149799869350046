import { TextField } from "@mui/material"


export default function Input(props) {

  const { name, text, setText, label} = props

  const handleChange = (e) => {
    setText(name, e.target.value)
  }

  return (<TextField label={label} value={text} onChange={handleChange} fullWidth />)
}