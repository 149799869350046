import { TextField } from "@mui/material"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function DatePicker(props) {

  const { label, name, date, setDate } = props
  const dateFormat = 'YYYY-MM-DD'

  const changeDate = (newDate) => {
    setDate(name, dayjs(newDate).format(dateFormat).toString())
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker  
        inputFormat={dateFormat}
        value={date}
        onChange={changeDate}
        renderInput={(params) => <TextField {...params} placeholder={dateFormat} label={label} fullWidth />} 
      />
    </LocalizationProvider>
  )
}

export default DatePicker