import { Typography, CircularProgress } from "@mui/material"
import { AppState } from '../redux/AppSlice'
import { useSelector } from 'react-redux'

function Breadcrumb(props) {

  const { text } = props
  const { loading } = useSelector(AppState)

  return (
    <>
    <Typography variant='h5' sx={{ flexGrow: 1 }}>{text} {loading && <CircularProgress size="1rem" />}</Typography>
    </>
  )
}

export default Breadcrumb