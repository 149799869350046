import { Card, CardContent, Chip, Divider, Stack, Typography, Box } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'

import { useEffect, useState } from "react"
import { RSGet, RSLog } from "../../Request"

import dayjs from "dayjs"
import BookingNumbers from "./BookingNumbers"

function News() {

  const [news, setNews] = useState([])
  
  useEffect(() => {
    RSGet('News')
    .then(data => {
      RSLog(data)
      setNews(data)
    })
    .catch(err => {
    })
  } ,[])

  return (
    <Grid container spacing={2}>
      <Grid xs={6}>
        <Card>
          <CardContent>
            {news.map(n => 
              <Stack key={n.id} spacing={1} mb={2}>
              <Typography variant="h6"><NewsTag type={n.type} /> {n.title}</Typography>
              <Typography variant="caption">{dayjs(n.ts).toString()}</Typography>
              <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>{n.content}</Typography>
              <Box mt={2}></Box>
              <Divider />
              </Stack>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid xs={3}>
        <BookingNumbers />
      </Grid>
   
    </Grid>
  )
}

function NewsTag(props) {
  const {type} = props

  const [label, setLable] = useState('系统')
  const [color, setColor] = useState('info')

  useEffect(()=>{
    switch(type) {
      case '0':
        
      break;

      case '1':
        setLable('房源')
        setColor('warning')
      break;

      default:

      break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<Chip label={label} color={color} size="small" />)
}

export default News