export function country(countryID) {
  switch(countryID) {
    case '0':
      return '英国'

    case '4':
      return '澳洲'

    default:
      return '-'
    
  }
}


export function currencySymbol(countryID) {
  switch(countryID) {
    case '0':
      return '£'

    case '1':
      return '¥'

    default:
      return '$'
  }
}
