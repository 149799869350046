import { useEffect, useState } from 'react'
import { RSPost, RSLog } from '../../Request'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Pagination from '@mui/material/Pagination'
import { Box, Stack } from '@mui/material'
import CitySelector from './CitySelector'
import Breadcrumb from '../../layout/Breadcrumb';
import AccommodationView from './AccommodationView'
import AccommodationSearch from './AccommodationSearch'

function StudentAccommodation() {

  const [cityID, setCityID] = useState(19)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [properties, setProperties] = useState([])
  const [viewOpen, setViewOpen] = useState(false)
  const [accommodation, setAccommodation] = useState(null)
 
  useEffect(()=>{
    setProperties(null)
    loadAccommodations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityID, page])

  useEffect(()=>{
    setPage(1)
  }, [cityID])

  const loadAccommodations = () => {
    RSPost('student-accommodation/list', {cityID: cityID, page: page})
      .then(data => {
        RSLog(data)
        setProperties(data.properties)
        setPageCount(data.pages)
      })
      .catch(err => {
      })
  }

  const loadAccommodation = (id) => {
    RSPost('student-accommodation/view', {id: id})
      .then(data => {
        RSLog(data)
        setAccommodation(data)
      })
      .catch(err => {
      })
  }

  const handleChangePage = (e, v) => {
    setPage(v)
  }

  const viewAccommodation = (id) => {
    loadAccommodation(id)
    setViewOpen(true)
  }

  /* List */
  const List = () => {
    return (
      <Grid container spacing={2} mt={1} mb={2}>
        {
          properties && properties.map((item) => <ListItem item={item} key={item.id} />)
        }
      </Grid>
    )
  }

  /* List item */
  const ListItem = (props) => {
    const { item } = props
    return (
      <Grid xs={2} onClick={() => viewAccommodation(item.id)}>
        <img src={item.img+'?x-oss-process=style/desktop_property_list'} width="100%" alt="" />
        { item.sold === '1' && <Chip label="FULL" size="small" color="error" sx={{float: 'right'}} />}
        <Typography>{item.name}</Typography>
        <Typography variant='caption'>{item.postcode}</Typography>
      </Grid>   
    )
  }


  return (
    <>

    <Stack direction="row" spacing={2}>
      <Breadcrumb text="学生公寓" />
      <AccommodationSearch />
      <CitySelector cityID={cityID} setCityID={setCityID} />
    </Stack>
    
    <List />
    <AccommodationView open={viewOpen} setOpen={setViewOpen} accommodation={accommodation} setAccommodation={setAccommodation} />
    {
      properties && pageCount>1 && 
      <Box display="flex" alignItems="center" justifyContent="center">
        <Pagination count={pageCount} shape="rounded" page={page} onChange={handleChangePage} />
      </Box>
    }
    </>
    
  )
}

export default StudentAccommodation