import { useState, useEffect } from "react"
import { RSLog, RSPost } from "../../Request"
import { Dialog, Button, DialogContent, LinearProgress, Alert} from '@mui/material'

export default function WechatBtn(props) {

  const [open, setOpen] = useState(false)
  const { paymentID, reload } = props
  const [qrcode, setQrcode] = useState()

  const handleClick = () => {
    // 桌面浏览器 二维码支付
    setOpen(true)
  }

  useEffect(() => {
    if(open) {
      RSLog('读取微信支付二维码')
      RSPost('BookingPayment/QRCode', {paymentID: paymentID})
        .then(data => {
          RSLog(data)
          setQrcode(data)
        })
      
      const interval = setInterval(() => {
        RSLog('检查订单支付状态')
        RSPost('BookingPayment/Check', {paymentID: paymentID})
          .then(data => {
            RSLog(data)
            if(data === 'done') {
              reload()
              setOpen(false)
            }
          })
      }, 2000);
      return () => clearInterval(interval);
      
    } else {
      setQrcode(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
    <Button onClick={handleClick} variant="contained">
      微信扫码支付
    </Button>
    <Dialog open={open} onClose={()=>setOpen(false)} maxWidth="xs" fullWidth>  
      <DialogContent>
        <div align="center">
          {!qrcode  && <LinearProgress />}
          {!qrcode && <Alert severity="info">读取二维码中</Alert>}
          {qrcode && <img src={qrcode} alt="Wechat pay qrcode" />}
          {qrcode && <Alert severity="info">支付成功后请稍等, 页面会自动刷新</Alert>}
        </div>
      </DialogContent>
    </Dialog>

    </>
  )
}