import { Typography, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { RSGet, RSLog, RSPost } from '../../Request';
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/AppSlice'

export default function AdvisorPicker(props) {
  const { booking, reload } = props
  const [advisors, setAdvisors] = useState(null)
  const [advisor, setAdvisor] = useState("0")
  const { scope } = useSelector(AppState)

  useEffect(() => {
    if(scope.admin) {
    RSGet('Booking/Team')
      .then(data => {
        setAdvisors(data)
      })
      .catch(err => {
      })
    }
  }, [])

  useEffect(() => {
    RSLog(booking.agent_account_id)
    setAdvisor(booking.agent_account_id)
  }, [booking])

  const handleChange = (e) => {
    setAdvisor(e.target.value)
    RSPost('BookingUpdate',{id:booking.id, agent_account_id:e.target.value})
    .then(data=>{
      reload()
      RSLog(data)
    })
    .catch(err=>{})
  }

  return (

    <>
    { scope.admin &&
    <>
      <Typography variant="body2">顾问</Typography>
      {advisors && 
      <Select
        value={advisor}
        onChange={handleChange}
        defaultValue=""
      >
        <MenuItem value="0"></MenuItem>
        {advisors.map(advisor => 
          <MenuItem key={advisor.id} value={advisor.id}>{advisor.name}</MenuItem>)
        }
        
      </Select>
      }
    </>
    }
    </>
  )
}