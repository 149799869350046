import { useState } from "react";
import { Box, Card, CardContent, Stack, TextField, Alert, Grid } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import logo from '../images/logo.png'
import { RSLog, RSPost } from '../Request'
import { setSigned } from '../redux/AppSlice'
import { useDispatch } from "react-redux";

function SignCard() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [warning, setWarning] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onChangeUsername = (e) => {
    setWarning(null)
    setUsername(e.target.value)
  }

  const onChangePassword = (e) => {
    setWarning(null)
    setPassword(e.target.value)
  }

  const login = () => {
    if(username !== '' && password !== "") {
      setLoading(true)
      RSPost('SignIn', {'username': username, 'password': password})
        .then( data => {
          RSLog(data);
          dispatch(setSigned(true))
          setLoading(false)
        })
        .catch( err => {
          setWarning(err)
          setLoading(false)
        })
    } else {
      setWarning('请填写用户名和密码')
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Box alignSelf='center' mt={1} mx={1}><img alt="logo" src={logo} width="250" /></Box>
              <TextField variant="outlined" label="用户名" value={username} onChange={onChangeUsername} />
              <TextField variant="outlined" label="密码" type="password" value={password} onChange={onChangePassword} />
              {warning && <Alert severity="error">{warning}</Alert>}
              <LoadingButton 
                loading={loading}
                variant="contained" 
                onClick={login}
              >渠道登录</LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </Grid>         
    </Grid> 
  )
}

export default SignCard;