import { Dialog, DialogContent, Stack, Typography, Box, LinearProgress, Divider } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { currencySymbol, country } from "../../utils/country"
import dayjs from 'dayjs'
import { useEffect, useState } from "react"
import BookingStateChip from "./BookingStateChip"
import AdvisorPicker from "./AdvisorPicker"
import { RSPost } from "../../Request"
import WechatBtn from "./WechatBtn"

export default function BookingView(props) 
{
  const { open, setOpen, booking, reload } = props


  const [rent, setRent] = useState(0)
  const [weeks, setWeeks] = useState(0)
  const [payments, setPayments] = useState([])

  useEffect(() => {
    if(booking) {
      const check_in = dayjs(booking.check_in)
      const check_out = dayjs(booking.check_out)
      const diff = check_out.diff(check_in, 'week')
      setWeeks(diff)
      setRent(diff*booking.price)
      loadTransactions()
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking])

  const closeDialog = () => {
    setOpen(false)
  }

  const loadTransactions = () => {
    RSPost('BookingPayments', {bookingID: booking.id})
    .then(data=>{
      setPayments(data)
    })
  }

  const gender = (v) => {
    if(v === '0') return '女';
    if(v === '1') return '男';
  }

  const degree = (v) => {
    if(v === '0') return '本科/预科';
    if(v === '1') return '硕士/预科';
    if(v === '2') return '其它';
  }

  const TitleItem = (props) => {
    const {v} = props
    return (<Typography variant="body1" fontWeight='bold'>{v}</Typography>)
  }

  const InfoItem = (props) => {
    const {k, v} = props
    return (<Typography variant="body2">{k}：{v}</Typography>)
  }

  return (
    
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={closeDialog}
    >
      {!booking && <LinearProgress />}
      {booking && 
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Stack spacing={2}>
              <Stack spacing={0.5}>
                <TitleItem v='客户信息' />
                <InfoItem k="姓名" v={booking.name+' ('+booking.name_en+')'} />
                <InfoItem k="性别" v={gender(booking.gender)} />
                <InfoItem k="生日" v={booking.dob} />
                <InfoItem k="国籍" v={booking.nationality} />
                <InfoItem k="学校" v={booking.university} />
                <InfoItem k="学位" v={degree(booking.degree)} />
              </Stack>

              <Stack spacing={0.5}>
                <TitleItem v='联系方式' />
                <InfoItem k="Email" v={booking.email} />
                <InfoItem k="国内手机" v={booking.mobile} />
                <InfoItem k="国外手机" v={booking.mobile_en} />
                <InfoItem k="微信" v={booking.wechat} />
                <InfoItem k="地址" v={booking.address} />
                <InfoItem k="邮编" v={booking.postcode} />
              </Stack>

              <Stack spacing={0.5}>
                <TitleItem v='担保人信息' />
                <InfoItem k="姓名" v={booking.name_ref} />
                <InfoItem k="关系" v={booking.relationship} />
                <InfoItem k="手机" v={booking.mobile_ref} />
                <InfoItem k="邮件" v={booking.email_ref} />
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={6}> 
            <Box sx={{float: 'right'}}><BookingStateChip id={booking.create_date} state={booking.state} /></Box>
            <Stack spacing={0.5}>
              <TitleItem v='订单信息' />
             
              
              <InfoItem k="国家" v={country(booking.country_id)} />
              <InfoItem k="城市" v={booking.city} />
              <InfoItem k="公寓" v={booking.accommodation} />
              <InfoItem k="房型" v={booking.room_type} />
              <InfoItem k="入住时间" v={booking.check_in} />
              <InfoItem k="结束时间" v={booking.check_out} />
              <InfoItem k="周租金" v={currencySymbol(booking.country_id) + booking.price} />
              <InfoItem k="租期" v={weeks+'周'} />
              <InfoItem k="总租金" v={currencySymbol(booking.country_id) + rent} />
              <Typography variant="caption" color="error">注意：租期和总租金信息以官网为准</Typography>
              <InfoItem k="提交时间" v={booking.create_date} />
              <InfoItem k="备注" v={booking.note} />

              <Divider />

              <AdvisorPicker booking={booking} reload={reload} />
            </Stack>
          </Grid>

          <Grid xs={12}>
            <Divider />
            <Box mt={2}></Box>
            { payments.length > 0 && <TitleItem v='在线支付' />}
            {
              payments.map(payment => 
                <Box mt={1} key={payment.id}>
                <Typography variant="body2">{payment.ts} - {payment.currency} - {payment.amount} - {paymentState(payment.status)} - {payment.note}</Typography>
                {payment.status === '0' && <WechatBtn paymentID={payment.id} reload={loadTransactions} />}
                </Box>
              )
            }



          </Grid>
        </Grid>
      </DialogContent>
      }
    </Dialog>
  )  
}

function paymentState(state) {
  let str = ''
  switch(state) {
    case "0":
      str = '待支付'
    break;

    case "1":
      str = '已支付'
    break;

    case "2":
      str = '已取消'
    break;

    case "3":
      str = '已代付'
    break;

    case "4":
      str = '已退款'
    break;
    default:
    break;
  }
  return str
}
