import { Grid, Stack } from '@mui/material'
import Bookings from './Bookings'
import Statement from './Statement'
import Breadcrumb from '../../layout/Breadcrumb'

export default function Commission()
{
  return (
  <Grid container spacing={2}>
    
    <Grid item xs={12} md={3} lg={2}>
      <Stack spacing={2}>
        <Breadcrumb text="结算记录" />
        <Statement />
      </Stack>
    </Grid>

    <Grid item xs={12} md={9} lg={10}>
      <Stack spacing={2}>
        <Breadcrumb text="待结算订单" />
        <Bookings />
      </Stack>
    </Grid>
  </Grid>
  )
}