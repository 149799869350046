import { useEffect, useState } from "react"
import { Button, Menu, MenuItem } from '@mui/material'

function AcademicYearSelector(props) {

  const { academicYear, setAcademicYear } = props
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [items, setItems] = useState([])

  useEffect(()=>{
    const startYear = 2022
    const thisYear = new Date().getFullYear();
    const diff = thisYear-startYear
    let arr = []
    for( let i = 0; i < diff+2; i++ ){
      arr.push({ay: (21+i)+'/'+(22+i)})
    }
    setItems(arr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openMenu = (e) => { 
    setOpen(true) 
    setAnchorEl(e.currentTarget)
  }
  const closeMenu = () => { 
    setOpen(false) 
    setAnchorEl(null)
  }

  const clickItem = (ayear) => {
    closeMenu()
    setAcademicYear(ayear)
  }

  return (
    <>
      <Button size="small" onClick={openMenu} variant="outlined">{academicYear} 学年</Button>
      <Menu 
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
      >
        {items.map(item => <MenuItem key={item.ay} onClick={()=>clickItem(item.ay)}>{item.ay}</MenuItem>)}
      </Menu>
    </>
  )
}

export default AcademicYearSelector