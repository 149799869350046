import { Route, Routes } from "react-router-dom"
import { Box, Container } from '@mui/material'
import Accommodation from '../pages/property/StudentAccommodation'
import Booking from '../pages/booking/Booking'
import Team from '../pages/team/Team'
import News from "../pages/news/News"
import Commission from "../pages/commission/Commission"

export default function Middle() {
  return (
    <Box pt={8} pb={6}>
      <Container maxWidth="xl">
        <Routes>
          <Route path="/" element={<News/>} />
          <Route path="/accommodation" element={<Accommodation />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/team" element={<Team />} />
          <Route path="/commission" element={<Commission />} />
        </Routes>
      </Container>
    </Box>
  )
}