import { Card, CardContent, Divider, Grid, Typography, Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { RSPost, RSLog } from "../../Request";
import AcademicYearSelector from '../form/AcademicYearSelector'

export default function BookingNumbers()
{
  const year = new Date().getFullYear().toString().substring(2, 4)
  const [academicYear, setAcademicYear] = useState((parseInt(year))+'/'+(parseInt(year)+1)) // 22/23
  const [numbers, setNumbers] = useState({total: 0, processing: 0, submited: 0, cancelled: 0})

  useEffect(()=>{
    RSPost('BookingNumbers', {academic: academicYear})
      .then(data=>{
        RSLog(data)
        setNumbers(data)
      })
      .catch(err=>{})
  }, [academicYear])

  return (
    <Card>
      <CardContent>
        <Grid container spancing={2}>
          <Grid item xs={12}>
            <Stack direction="row">
              <Typography variant="h6" sx={{flexGrow: 1}}>订单数</Typography>
              <AcademicYearSelector academicYear={academicYear} setAcademicYear={setAcademicYear} />
            </Stack>
            <Typography variant="h2" align="center">{numbers.total}</Typography>
            <Typography variant="body2" align="center">入住时间在8月初到次年7月底之间</Typography>
          </Grid>  
          <Grid item xs={12}>
            <Box mt={2} mb={2}><Divider /></Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4" align="center">{numbers.processing}</Typography>
            <Typography variant="body2" align="center">办理中</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4" align="center">{numbers.submited}</Typography>
            <Typography variant="body2" align="center">已提交</Typography>
          </Grid>  
          <Grid item xs={4}>
            <Typography variant="h4" align="center">{numbers.cancelled}</Typography>  
            <Typography variant="body2" align="center">已取消</Typography>
          </Grid>  
        </Grid>
      </CardContent>
    </Card>
  )
}