import { Card } from '@mui/material'
import { useEffect, useState } from 'react'
import { RSGet, RSLog } from '../../Request'
import BookingTable from '../booking/BookingTable'

export default function Bookings()
{

  const [bookings, setBookings] = useState([])

  useEffect(() => {
    RSGet('Commission/Bookings')
    .then(data => {
      RSLog(data)
      setBookings(data)
    })
    .catch(err => {

    })
  }, [])

  return(
  <Card>
    <BookingTable bookings={bookings} simple={true} />
  </Card>
  )
}