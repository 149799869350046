import Top from './Top'
import Middle from "./Middle"
import { setScope } from '../redux/AppSlice'
import { useDispatch } from "react-redux";
import { useEffect } from "react"
import { RSGet, RSLog } from "../Request"
import { ToastContainer } from 'react-toastify'

function Layout() {

  const dispatch = useDispatch()

  useEffect(() => {
    RSGet('Scope')
      .then( data => {
        RSLog(data);
        dispatch(setScope(data))

        /*检测版本 自动刷新*/
        const version = window.localStorage.getItem('verison')
        if(version !== data['version']) {
          RSLog('版本更新 自动刷新')
          window.localStorage.setItem('verison', data['version'])
          window.location.reload()
        }

      })
      .catch( err => {
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <>
        <Top />
        <Middle />
        <ToastContainer />
      </>
  )
}

export default Layout